import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import BusinessInfo from '../../Shared/BusinessInfo'

const AddCustomer = () => {
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const navigate= useNavigate();

    const handleAddCustomer = (data) => {
        fetch("https://servers.thakurgaonexpress.com/nbcmotors/addcustomer", {
            method: "POST",
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                toast.success('Your Registration Complete, You Can Book Now')
                navigate('/');
            });
        reset();

    }

    return (
        <div>
            <div className='flex justify-center items-center flex-col'>
                <h1 className='mt-8 mb-4 text-lg border-b-4 border-red-600 inline-block mt-20'>Register In {BusinessInfo.name}, Mawna</h1>
            </div>
            <form onSubmit={handleSubmit(handleAddCustomer)} className='flex justify-center items-center flex-col'>
                <div class="form-control w-80">
                    <label class="label">
                        <span class="label-text">Your Bike Purchase Date </span>
                    </label>
                    <input  {...register("purchaseDate", { required: true })} type="date" placeholder="Date" class="input input-bordered w-full max-w-xs" required />
                </div>
                <div class="form-control w-80">
                    <label class="label">
                        <span class="label-text">Your Name</span>
                    </label>
                    <input  {...register("name", { required: true })} type="text" placeholder="Enter Your Name" class="input input-bordered w-full max-w-xs" required />
                </div>
                <div class="form-control w-80">
                    <label class="label">
                        <span class="label-text">Your Mobile No</span>
                    </label>
                    <input  {...register("phone", { required: true })}  type="text" placeholder="Enter Your Number" class="input input-bordered w-full max-w-xs" required />

                </div>
                <div class="form-control w-80">
                    <label class="label">
                        <span class="label-text">Bike Model</span>
                    </label>
                    <input  {...register("model", { required: true })}  type="text" placeholder="Enter Bike Model" class="input input-bordered w-full max-w-xs" required />
                </div>
                <div class="form-control w-80">
                    <label class="label">
                        <span class="label-text">Chassis No</span>
                    </label>
                    <input  {...register("chassis", { required: true })} type="text" placeholder="Enter Bike Chassis No" class="input input-bordered w-full max-w-xs" required />
                </div>
                <button className='btn btn-md btn-error text-white my-4 rounded-md' type='submit'>Register</button>
            </form>
        </div>
    );
};

export default AddCustomer;