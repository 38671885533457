import logo from '../../assets/images/logo.jpg'

const BusinessInfo = {
    name: "NBC Motors",
    fullName: "NBC Motors",
    dealer: "(Authorized Dealer Of Bangladesh Honda Private Limited)",
    address: "Mawna Chawrasta, Sreepur, Gazipur.",
    mobile: "Mobile: 01958-486256,01958-486257",
    img: logo,
}

export default BusinessInfo;