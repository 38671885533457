import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import UpdateModal from './UpdateModal';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import Loader from '../../../Shared/Loader';
import { format } from 'date-fns';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useRef } from 'react';


const CollectionField = ({ }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [collections, setCollections] = useState([]);
    const [updateCollection, setUpdateCollection] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isExpend, setIsExpend] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [isCustomerSearching, setIsCustomerSearching] = useState(false);
    const [customerText, setCustomerText] = useState([]);
    const [customerSearch, setCustomerSearch] = useState([]);
    const [sort, setSort] = useState('Newest First');
    const [isFilter, setIsFilter] = useState(false);
    const [currentCustomer, setCurrentCustomer] = useState([]);
    const [finalDue, setFinalDue] = useState(0);
    const [isCollect, setIsCollect] = useState(false);

    console.log(collections);
    const sortRef = useRef('');
    const codeRef = useRef('');
    const nameRef = useRef('');




    const handleSort = () => {
        const sortValue = sortRef.current.value;
        if (sortValue === "Oldest First") {
            setSort("Oldest First")
        } else {
            setSort("Newest First")
        }
    }
    const date = new Date();
    const today = format(date, "yyyy-MM-dd");

    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/nbcmotors/getcollections')
            .then(res => res.json())
            .then(data => {
                if (sort !== "Oldest First") {
                    setCollections(data)
                } else {
                    const sorted = data.sort(function (a, b) {
                        return (a?.date > b?.date) ? 1 : ((a?.date < b?.date) ? -1 : 0);
                    });
                    setCollections(sorted);
                }
                setLoading(false)
            })
    }, [sort])

    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/nbcmotors/getmaincustomers')
            .then(res => res.json())
            .then(data => {
                const filter = data?.filter(eh => eh?.status === "Active")
                setCustomers(filter);
                setLoading(false)
            })
    }, [])

    if (loading) {
        return <Loader />
    }

    const onSubmit = data => {
        const currentDate = new Date(data.date);
        const formattedDate = format(currentDate, 'MMM-yyyy');
        const collection = parseInt(data.amount);
        const code = codeRef.current.value;
        const name = nameRef.current.value;
        const user = userAc?.email.split('@')[0];

        const newData = { ...data, code, name, month: formattedDate, collection,user }
        fetch('https://servers.thakurgaonexpress.com/nbcmotors/addcollection', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(newData),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Collection Added')
                reset();
                setTimeout(() => { window.location.reload(); }, 3000);
            })

    }

    const handleDeleteCollection = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://servers.thakurgaonexpress.com/nbcmotors/getcollection/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                    setUpdateCollection(null);
                    setTimeout(() => { window.location.reload(); }, 3000);
                })
        }
    };

    const handleFilteredData = e => {
        e.preventDefault();
        const startDate = e.target.startDate.value;
        const endDate = e.target.endDate.value;
        const name = e.target.category.value;
        const sortBy = e.target.sort.value;
        const url = `https://servers.thakurgaonexpress.com/nbcmotors/filteredcollections?startDate=${startDate}&endDate=${endDate}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                if (name === "All") {
                    if (sortBy !== "Oldest First") {
                        setCollections(data);
                    } else {
                        const sorted = data.sort(function (a, b) {
                            return (a?.date > b?.date) ? 1 : ((a?.date < b?.date) ? -1 : 0);
                        });
                        setCollections(sorted);
                    }
                } else {

                    if (sortBy !== "Oldest First") {
                        const filteredCollections = data.filter(collection => collection.name === name);
                        setCollections(filteredCollections);
                    } else {
                        const filteredCollections = data.filter(collection => collection.name === name);
                        const sorted = filteredCollections.sort(function (a, b) {
                            return (a?.date > b?.date) ? 1 : ((a?.date < b?.date) ? -1 : 0);
                        });
                        setCollections(sorted);
                    }
                }
                setIsFilter(true);
            })
    }

    const handleUpdateCollection = (collection) => {
        setUpdateCollection(collection);
    }

    const handleSearchResult = (event) => {
        const searchText = event.target.value;
        const match = collections.filter(collection => collection.name.includes(searchText)
            || collection.purpose.includes(searchText) || collection.amount.includes(searchText)
            || collection.date.includes(searchText) || collection.invoice.includes(searchText) ||
            collection.code.includes(searchText));
        setSearchResult(match);
        setIsSearching(true);
    }

    const handleSearchCustomer = (event) => {
        setCustomerText(event.target.value)
        const match = customers.filter(customer => customer.name.toLowerCase().includes(customerText)
            || customer.name.includes(customerText) || customer.name.toUpperCase().includes(customerText));
        setCustomerSearch(match);
        setIsCustomerSearching(true);
    }

    const handleSelectCustomer = (customer) => {
        console.log(customer);
        const opening = parseFloat(customer?.opening)
        console.log(opening);
        const url = `https://servers.thakurgaonexpress.com/nbcmotors/filteredmaincustomer?code=${customer.code}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                console.log(data);
                const due = opening + data?.defaultTotalDue - data?.defaultTotalCollect;
                setCurrentCustomer({ ...customer, due });
            })
        setIsCustomerSearching(false)
        setCustomerText('');
    }

    const handleFinalDue = (event) => {
        const currentDue = currentCustomer?.due;
        const due = currentDue - parseInt(event?.target?.value);
        console.log(due);
        setFinalDue(due);
        setIsCollect(true);
    }

    const handleApprove=(collection)=>{
        const approvedBy = userAc?.email.split('@')[0];
        fetch(`https://servers.thakurgaonexpress.com/nbcmotors/getcollection/${collection._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({approvedBy})
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                toast('Collection Approved');
                // window.location.reload(false)
            })
    }

    const totalAmount = !isSearching ? collections.reduce((total, currentValue) => total + parseInt(currentValue?.amount), 0)
        : searchResult.reduce((total, currentValue) => total + parseInt(currentValue?.amount), 0);
    return (
       <div>
         <div className={`${isExpend ? 'shadow-2xl' : ''} px-12 pb-5 mt-5 text-xs`}>
            <div className='text-right'><button onClick={() => setIsExpend(!isExpend)} className='btn btn-sm btn-error text-white'>{isExpend === true ? <AiOutlineMinus className='text-lg font-bold' /> : <AiOutlinePlus className='text-lg font-bold' />}Add collection</button></div>
            <div className={`${isExpend === true ? "" : "hidden"}`}>
                <div class="form-control w-full max-w-xs mt-5 col-span-2">
                    <div class="form-control w-full">
                        <input onChange={handleSearchCustomer} value={customerText} type="text" placeholder="Search Curstomer" class="rounded-lg bg-red-300 p-3 placeholder-black  text-center  focus:border-blue-500 focus:outline-none w-full max-w-xs" />
                    </div>
                    <div style={isCustomerSearching ? {} : { visibility: 'hidden' }} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 bg-gray-200 p-2 ${isCustomerSearching ? 'h-16' : "h-8"} w-full overflow-auto`}>
                        {
                            isCustomerSearching ? customerSearch.map(customer =>
                                <label for="update-modal" onClick={() => handleSelectCustomer(customer)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{customer.code}. {customer?.name}</label>) :
                                customers.map(customer =>
                                    <label for="update-modal" onClick={() => handleSelectCustomer(customer)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{customer.code}. {customer?.name}</label>)
                        }
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className={`container grid grid-cols-1 lg:grid-cols-3 gap-1 justify-center items-center`}>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Customer Code</span>
                        </label>
                        <input disabled ref={codeRef} value={currentCustomer?.code} type="text" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Customer Name</span>
                        </label>
                        <input disabled ref={nameRef} value={currentCustomer?.name} type="text" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Previews Due</span>
                        </label>
                        <input disabled type="number" value={currentCustomer?.due} class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Amount</span>
                        </label>
                        <input {...register("amount")} onChange={handleFinalDue} type="number" placeholder="Type Amount Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Final Due</span>
                        </label>
                        <input disabled type="number" value={isCollect === true ? finalDue : currentCustomer?.due} class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Select Date</span>
                        </label>
                        <input {...register("date")} type="date" defaultValue={today} placeholder="Date" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Invoice No</span>
                        </label>
                        <input {...register("invoice")} type="text" placeholder="Type Invoice No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>


                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Remarks</span>
                        </label>
                        <input {...register("purpose")} type="text" placeholder="Type Remarks Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Status</span>
                        </label>
                        <select style={{ padding: '18px' }} {...register("status")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                            <option selected>Active</option>
                            <option>Inactive</option>
                        </select>
                    </div>
                    <input disabled={!userAc?.addCollection} type="submit" value='Add Collection' className='btn w-full lg:w-60 bg-red-600 text-white mt-2 lg:mt-9' />
                </form>
            </div>
            </div>
            <div className='text-xs mx-2 shadow-2xl p-2 lg:p-5'>
            <div className={`${isExpend === false ? "" : "hidden"}`}>
                <div className='flex justify-center lg:justify-end items-center'>
                    <form onSubmit={handleFilteredData} className='mt-12 flex flex-col lg:flex-row justify-center items-right'>
                        <div class="form-control w-full lg:w-40 max-w-xs lg:mr-2">
                            <label class="label">
                                <span class="label-text">Starting Date</span>
                            </label>
                            <input name='startDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full lg:w-40 max-w-xs lg:mr-2">
                            <label class="label">
                                <span class="label-text">End Date</span>
                            </label>
                            <input name='endDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full lg:w-60 max-w-xs lg:mr-2">
                            <label class="label">
                                <span class="label-text">Customer</span>
                            </label>
                            <select style={{ padding: '' }} {...register("category")} defaultValue='' class="rounded-lg text-black text-xs select select-bordered w-full max-w-xs" required>
                                <option selected>All</option>
                                {
                                    customers.map(customer => <option>{customer.name}</option>)
                                }
                            </select>
                        </div>
                        <div class={`form-control w-full lg:w-40 max-w-xs lg:mr-2`}>
                            <label class="label">
                                <span class="label-text">Short By</span>
                            </label>
                            <select style={{ padding: '' }} {...register("sort")} defaultValue='' class="rounded-lg text-black text-xs select select-bordered w-full max-w-xs" required>
                                <option selected>Newest First</option>
                                <option>Oldest First</option>
                            </select>
                        </div>

                        <input type='submit' value='Search' className='btn btn-md btn-error text-white mt-4 lg:mt-9 lg:ml-2' />
                    </form>
                </div>
                <div className='flex justify-end items-center my-8 lg:my-4'>
                   <div class="form-control w-full lg:w-60">
                        <input onChange={handleSearchResult} type="text" placeholder="Search Collection" class="input text-xs mx-auto input-bordered bg-white input-error w-full max-w-xs" />
                    </div>
                </div>
                <div className={`h-8 ${isFilter === false ? "flex justify-between items-center" : "text-right"}`}>
                    <div className={`form-control w-40 max-w-xs lg:mr-2 ${isFilter === true ? "hidden" : ""}`}>
                        <label class="label">
                            <span class="label-text">Short By</span>
                        </label>
                        <select ref={sortRef} style={{ padding: '' }} onChange={handleSort} defaultValue='' class="rounded-lg text-black text-xs select select-bordered w-full max-w-xs" required>
                            <option selected>Newest First</option>
                            <option>Oldest First</option>
                        </select>
                    </div>
                    <div className='text-right'>
                        <ReactHtmlTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-error text-white btn-xs mt-10 lg:mt-0"
                            table="table-to-xls"
                            filename="collections-table"
                            sheet="collections"
                            buttonText="Export to exel" />
                    </div>
                </div>
                {
                    collections.length ? <div class="overflow-x-auto mt-8">
                        <table id="table-to-xls" class="table w-1/2 mx-auto">
                            <thead className='text-center'>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>SL</th>
                                    <th className='bg-red-600 text-white normal-case'>Date</th>
                                    <th className='bg-red-600 text-white normal-case'>Customer Code</th>
                                    <th className='bg-red-600 text-white normal-case'>Customer Name</th>
                                    <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                                    <th className='bg-red-600 text-white normal-case'>Amount</th>
                                    <th className='bg-red-600 text-white normal-case'>Purpose</th>
                                    <th className='bg-red-600 text-white normal-case'>Status</th>
                                    <th className='bg-red-600 text-white normal-case'>Added By</th>
                                    <th className='bg-red-600 text-white normal-case'>Approved By</th>
                                    <th className='bg-red-600 text-white normal-case'>Edit</th>
                                    <th className='bg-red-600 text-white normal-case'>Delete</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                {
                                    isSearching ? searchResult.map((collection, index) => <tr className='hover'>
                                        <td>{index + 1}</td>
                                        <td>{collection.date}</td>
                                        <td>{collection.code}</td>
                                        <td>{collection.name}</td>
                                        <td>{collection.invoice}</td>
                                        <td>{collection.amount}</td>
                                        <td>{collection.purpose}</td>
                                        <td> <button className={`btn btn-xs normal-case ${collection.status === 'Active' ? 'bg-green-700' : 'bg-red-700'}`}>{collection?.status}</button> </td>
                                        <td><button className='btn btn-xs btn-primary text-white normal-case'>{collection?.user ? collection.user : "User"}</button></td>
                                        {userAc.approvalCollection? <td>{collection?.approvedBy?<button className='btn btn-xs btn-success text-white normal-case'>{collection?.approvedBy}</button>: <button onClick={()=>handleApprove(collection)} className='btn btn-xs btn-secondary text-white normal-case'>Approve</button>}</td>:
                                   <td>{collection.approvedBy?<button className='btn btn-xs btn-success text-white normal-case'>{collection.approvedBy}</button>:<button className='btn btn-xs btn-error text-white'>Not Approved</button>}</td>}
                                        {userAc?.editCollection && <td><button><label for="update-modal" onClick={() => handleUpdateCollection(collection)} className='text-xl text-green-700 cursor-pointer'><FaEdit /></label></button></td>}
                                        {userAc?.deleteCollection && <td><button onClick={() => handleDeleteCollection(collection._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                    </tr>) : collections.map((collection, index) => <tr className='hover'>
                                        <td>{index + 1}</td>
                                        <td>{collection.date}</td>
                                        <td>{collection.code}</td>
                                        <td>{collection.name}</td>
                                        <td>{collection.invoice}</td>
                                        <td>{collection.amount}</td>
                                        <td>{collection.purpose}</td>
                                        <td> <button className={`btn btn-xs normal-case ${collection.status === 'Active' ? 'bg-green-700' : 'bg-red-700'}`}>{collection?.status}</button> </td>
                                        <td><button className='btn btn-xs btn-primary text-white normal-case'>{collection?.user ? collection.user : "User"}</button></td>
                                        {userAc.approvalCollection? <td>{collection?.approvedBy?<button className='btn btn-xs btn-success text-white normal-case'>{collection?.approvedBy}</button>: <button onClick={()=>handleApprove(collection)} className='btn btn-xs btn-secondary text-white normal-case'>Approve</button>}</td>:
                                   <td>{collection.approvedBy?<button className='btn btn-xs btn-success text-white normal-case'>{collection.approvedBy}</button>:<button className='btn btn-xs btn-error text-white'>Not Approved</button>}</td>}
                                        {userAc?.editCollection && <td><button><label for="update-modal" onClick={() => handleUpdateCollection(collection)} className='text-xl text-green-700 cursor-pointer'><FaEdit /></label></button></td>}
                                        {userAc?.deleteCollection && <td><button onClick={() => handleDeleteCollection(collection._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                    </tr>)
                                }
                            </tbody>
                            <tfoot className='text-center'>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                    <th className='bg-red-600 text-white normal-case'>Total</th>
                                    <th className='bg-red-600 text-white normal-case'>{totalAmount}</th>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                </tr>
                            </tfoot>
                        </table>
                    </div> : ""
                }
            </div>
            </div>
                {
                    updateCollection && <UpdateModal setUpdateCollection={setUpdateCollection} updateCollection={updateCollection}></UpdateModal>
                }
        </div>
    );
};

export default CollectionField;