import { createSlice } from "@reduxjs/toolkit"


const initialState={
    brand: [],
    category: [],

}

const infoSlice= createSlice({
    name: 'info',
    initialState,
    reducers:{
        editBrand:(state, action)=>{
            state.brand= action.payload;
        },
        editCategory:(state, action)=>{
            state.category= action.payload;
        }
    }
});

export const {editBrand, editCategory}= infoSlice.actions;
export default infoSlice.reducer;