import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

const usePaginationSearch = (url) => {
    const [items, setitems] = useState([]);
    const [pageCount, setPageCount] = useState();
    const [limit, setLimit] = useState(20);
    const [pageNo, setPageNo] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(true);
    const [totalItem, setTotalItem] = useState()

    useEffect(() => {
        fetch(`${url}?search=${searchText}&page=1&limit=${limit}`)
            .then(res => res.json())
            .then(data => {
                setitems(data.total);
                setPageCount(Math.ceil(data.document / limit))
                setLoading(false)
                setTotalItem(data.myLength);
            })
    }, [limit])

    const handleSearchResult = (event) => {
        const searchText = event.target.value
        fetch(`${url}?search=${searchText}&page=1&limit=${limit}`)
            .then(res => res.json())
            .then(data => {
                setitems(data.total);
                if (searchText.length) {
                    setPageCount(Math.ceil(data.total.length / limit))
                } else {
                    setPageCount(Math.ceil(data.document / limit))
                }
                setLoading(false)
            })
        // const match = products.filter(product => product.name.includes(searchText)
        //     || product.name.toLowerCase().includes(searchText)
        //     || product.name.toLowerCase().includes(searchText));
        // setSearchResult(match);
        // setIsSearching(true);
    }

    const handleLimit = (event) => {
        const limited = event.target.value === "All" ? "All" : parseInt(event.target.value);
        console.log(limited)
        setLimit(limited);
    }
    // console.log(categories);

    const handlePageClick = (data) => {
        console.log(data.selected);
        fetch(`${url}?search=${searchText}&page=${data.selected + 1}&limit=${limit}`)
            .then(res => res.json())
            .then(data => {
                setitems(data.total);
                setPageCount(Math.ceil(data.document / limit))
                setLoading(false)
                console.log(data);
            })
        setPageNo(data.selected + 1);
       
    }

    return { items, pageCount,totalItem, limit, pageNo, searchText, loading, handleSearchResult, handleLimit, handlePageClick };
};

export default usePaginationSearch;
